/* eslint-disable */
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import warningPic from '@assets/Dispute.svg'

import { actions as accountActions, selectors as accountSelectors } from '@modules/account'
import { actions as authActions, selectors as authSelectors } from '@modules/auth'
import { useActions } from '@hooks/use-actions'
import Loader from '@components/loader'
import GrvIcon from '@components/grv-icon'
import { reinit as storeReinit } from '@src/store'
import { startSessionTimer } from '@util/session-timer'
import { formatAmountToCurrency } from '@util/currency'
import { getCookies } from '@util/cookies'
import { mockConstants } from '@util/mock-constants'
import { isOfflineModeOnAndAvailable } from '@util/check-offline-mode'
import { selectors as transactionSelector } from '@modules/transaction'

const AccountSelection = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const offlineMode = useSelector(transactionSelector.offlineMode)
  const accounts = useSelector(accountSelectors.accounts)
  // This is called again here because of a reinit that wipes the previous state. It will
  // also be used on this page for validation and error handling in a future ticket
  const isRetrievingAccounts = useSelector(accountSelectors.accountLoading)
  const reinit = useActions(storeReinit)
  const [fetchAccounts, setAccounts, offerAccountThenGotoCheckForm, setProfileReferenceId] =
    useActions([
      accountActions.fetchAccounts,
      accountActions.setAccounts,
      accountActions.offerAccountThenGotoCheckForm,
      authActions.setProfileReferenceId
    ])

  const formatAccountString = accountString => {
    const formattedAccounts = []
    /* eslint-disable-next-line array-callback-return */
    accountString.split(' and ').map(account => {
      formattedAccounts.push(
        <span key={account}>
          <b>{account}</b>
        </span>
      )
    })

    formattedAccounts.splice(1, 0, <span key='conjunction'>{' and '}</span>)

    return formattedAccounts
  }

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === 'DEV') {
      const cookies = getCookies()
      const prid = cookies.C1_PRID
      setProfileReferenceId(prid)
    }
    if (isOfflineModeOnAndAvailable(offlineMode)) {
      setAccounts(mockConstants.accounts)
    } else {
      fetchAccounts(navigate)
    }
  }, [setProfileReferenceId, getCookies, fetchAccounts, isAuthenticated, offlineMode, setAccounts])

  startSessionTimer(navigate)

  if (isRetrievingAccounts) {
    return <Loader />
  }
  if (!accounts || accounts.length <= 0) {
    return (
      <div className='content-box'>
        <div style={{ textAlign: 'center' }}>
          <img
            style={{ width: '75px', height: '75px', marginBottom: '20px' }}
            alt='Warning pic'
            src={warningPic}
          />
          <h1 className='wizard-page-title'>{t('accountSelection.noAccountsHeader')}</h1>
          <p style={{ marginBottom: 30 }} className='grv-text__body--small'>
            {t('accountSelection.noAccountsText')}
          </p>
          <button
            type='button'
            tabIndex={0}
            className='progress-button'
            style={{ width: '100%', marginTop: '20px' }}
            onClick={() => {
              reinit()
              window.location.replace(process.env.REACT_APP_ACKNOWLEDGMENT_REDIRECT)
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                reinit()
                window.location.replace(process.env.REACT_APP_ACKNOWLEDGMENT_REDIRECT)
              }
            }}
          >
            {t('accountSelection.okButton')}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className='content-container'>
      <h2 id='account-selection-header' className='content-title'>
        {t('accountSelection.contentTitle')}
      </h2>
      <div id='account-list' className='account-list'>
        {accounts.map((a, key) => (
          <React.Fragment key={key}>
            <div
              id={`account-list-item-${key}`}
              className='account-list-item'
              role='button'
              tabIndex={0}
              onClick={() => offerAccountThenGotoCheckForm(a, navigate)}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  offerAccountThenGotoCheckForm(a, navigate)
                }
              }}
            >
              <div>
                <span className='grv-text__body--medium'>{a.accountName}</span>
                <br />
                <span className='grv-text__body--normal'>
                  {formatAmountToCurrency(a.availableBalance)}{' '}
                  {t('accountSelection.availableBalance')}
                </span>
              </div>
              <GrvIcon iconClass='ui-lined-arrow-right-1-24' />
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className='grv-text__heading--small'>{t('accountSelection.disclaimerHeader')}</div>
      <div className='grv-text__body--small'>
        <span>{t('accountSelection.disclaimer.before')}</span>
        {formatAccountString(t('accountSelection.disclaimer.accounts'))}
        <span>{t('accountSelection.disclaimer.after')}</span>
      </div>
    </div>
  )
}

export default AccountSelection
