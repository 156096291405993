import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { actions as checkActions, selectors as checkSelectors } from '@modules/check'
import { selectors as accountSelectors } from '@modules/account'
import { selectors as transactionSelectors } from '@modules/transaction'
import { selectors as authSelectors } from '@modules/auth'
import { useIsContentOverflowing } from '@hooks/use-is-content-overflowing'
import { useActions } from '@hooks/use-actions'
import Loader from '@components/loader'
import { log } from '@api/ccc-api-calls'
import { isOfflineModeOnAndAvailable } from '@util/check-offline-mode'

const CheckConfirmation = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const payee = useSelector(state => state.check.payee)
  const displayAmount = useSelector(checkSelectors.displayAmount)
  const account = useSelector(accountSelectors.account)
  const memo = useSelector(state => state.check.memo)
  const accessToken = useSelector(authSelectors.accessToken)
  const clientCorrelationId = useSelector(transactionSelectors.clientCorrelationId)
  const kioskName = useSelector(transactionSelectors.kioskName)
  const offlineMode = useSelector(transactionSelectors.offlineMode)
  const offerCheckConfirmationThenGotoQrCodeDemonstration = useActions(
    checkActions.offerCheckConfirmationThenGotoQrDemo
  )
  const offerCheckConfirmationThenGotoEaseMobile = useActions(
    checkActions.offerCheckConfirmationThenGotoEaseMobile
  )
  const [isAccountConfirmed, setAccountConfirmed] = useState(false)
  const [isPayeeConfirmed, setPayeeConfirmed] = useState(false)
  const [isAmountConfirmed, setAmountConfirmed] = useState(false)
  const [isMemoConfirmed, setMemoConfirmed] = useState(false)
  const [isDisclaimerConfirmed, setDisclaimerConfirmed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const bodyElement = document.querySelector('body')
  const [contentContainerRef, btnRef] = useIsContentOverflowing(bodyElement, 2, [
    [0, e => e.offsetTop]
  ])
  const [continueExperience, setContinueExperience] = useState('browser')

  useEffect(() => {
    const value = window.localStorage.getItem('continueExperience')

    if (value) {
      setContinueExperience(value)
    }
  }, [window, setContinueExperience])

  const formFields = {
    account: {
      id: 'isAccountConfirmedCheckbox',
      label: 'Account',
      data: account.accountName
    },
    payee: {
      id: 'isPayeeConfirmedCheckbox',
      label: t('checkConfirmation.payeeLabel'),
      data: payee
    },
    amount: {
      id: 'isAmountConfirmedCheckbox',
      label: t('checkConfirmation.amountLabel'),
      data: displayAmount
    },
    memo: {
      id: 'isMemoConfirmedCheckbox',
      label: t('checkConfirmation.memoLabel'),
      data: memo
    }
  }

  /* eslint-disable jsx-a11y/label-has-associated-control */
  const checkConfirmationRow = (row, checkboxState, checkboxStateChange) => {
    return (
      <div className='check-confirmation-checkbox-row'>
        <p className='form-field-label' style={{ fontSize: 14 }}>
          {formFields[row].label}
        </p>
        <div className='check-confirmation-checkbox-details'>
          {row !== 'memo' || formFields[row].data ? (
            <div id={row} className='grv-text__body--normal check-confirmation-data'>
              {formFields[row].data}
            </div>
          ) : (
            <div id={row} className='grv-text__body--normal blank-memo-text'>
              No memo specified
            </div>
          )}

          <div style={{ position: 'relative' }}>
            <input
              id={formFields[row].id}
              className='checkbox'
              type='checkbox'
              checked={checkboxState}
              onChange={() => checkboxStateChange(checkboxState => !checkboxState)}
              value='true'
            />
            <label
              aria-label={`${row} checkbox`}
              className='checkbox-label'
              style={{ position: 'absolute', bottom: 24, right: 0 }}
              htmlFor={formFields[row].id}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {isLoading && (
        <div style={{ zIndex: 999, position: 'fixed' }}>
          <Loader style={{ backgroundColor: 'rgba(169, 169, 169, 0.43)', cursor: 'default' }} />
        </div>
      )}
      <div ref={contentContainerRef} className='content-container'>
        <h2 id='check-confirmation-header' className='content-title'>
          {t('checkConfirmation.header')}
        </h2>

        <p className='subheader'>{t('checkConfirmation.subheader')}</p>
        <div style={{ margin: '10px 0px' }}>
          {checkConfirmationRow('account', isAccountConfirmed, setAccountConfirmed)}
          {checkConfirmationRow('payee', isPayeeConfirmed, setPayeeConfirmed)}
          {checkConfirmationRow('amount', isAmountConfirmed, setAmountConfirmed)}
          {checkConfirmationRow('memo', isMemoConfirmed, setMemoConfirmed)}
        </div>

        <div
          id='confirm-disclaimer-checkbox'
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
            marginBottom: 20
          }}
        >
          <div
            id='check-details-disclaimer'
            style={{ marginLeft: 10, overflow: 'hidden' }}
            className='grv-text__body--tiny'
          >
            {t('checkConfirmation.disclaimer')}
          </div>
          <div>
            <input
              id='isDisclaimerConfirmedCheckbox'
              className='checkbox'
              type='checkbox'
              value='true'
              checked={isDisclaimerConfirmed}
              onChange={() => setDisclaimerConfirmed(!isDisclaimerConfirmed)}
            />
            <label
              aria-label='disclaimer confirmation checkbox'
              className='checkbox-label'
              htmlFor='isDisclaimerConfirmedCheckbox'
            />
          </div>
        </div>

        <button
          id='check-confirmation-button'
          type='button'
          ref={btnRef}
          className='progress-button'
          disabled={
            !isAccountConfirmed ||
            !isPayeeConfirmed ||
            !isAmountConfirmed ||
            !isMemoConfirmed ||
            !isDisclaimerConfirmed
          }
          onClick={() => {
            const logObject = JSON.stringify({
              'Checkbox Text': t('checkConfirmation.disclaimer')
            })
            setIsLoading(true)
            log(
              kioskName,
              clientCorrelationId,
              `Customer has checked checkbox: ${logObject}`,
              accessToken
            )

            if (continueExperience === 'easeApp' && !isOfflineModeOnAndAvailable(offlineMode)) {
              offerCheckConfirmationThenGotoEaseMobile(navigate)
            } else {
              offerCheckConfirmationThenGotoQrCodeDemonstration(navigate)
            }
          }}
        >
          {t('checkConfirmation.looksGood')}
        </button>
      </div>
    </>
  )
}

export default CheckConfirmation
