export const mockConstants = Object.freeze({
  customerCheckCentsLimit: 250000,
  accounts: [
    {
      availableBalance: 4992093.25,
      productName: 'Simply Checking',
      tokenizedAccountReferenceId: 'jg5mFCxvS9+soKzXLPBNRMqZ1127KaWidNRNVBMWD0Q=',
      accountReferenceId: 'jg5mFCxvS9+soKzXLPBNRMqZ1127KaWidNRNVBMWD0Q=',
      accountNumberLastFour: '4789',
      accountNickname: '',
      productId: '4700'
    },
    {
      availableBalance: 4999828.46,
      productName: '360 Checking',
      tokenizedAccountReferenceId: 'jg5mFCxvS9+soKzXLPBNRHz1n9wCtYXnbwVtXGf/iPA=',
      accountReferenceId: 'jg5mFCxvS9+soKzXLPBNRHz1n9wCtYXnbwVtXGf/iPA=',
      accountNumberLastFour: '4734',
      accountNickname: '',
      productId: '4000'
    },
    {
      availableBalance: 5000567.23,
      productName: 'Total Control Checking',
      tokenizedAccountReferenceId: 'jg5mFCxvS9+soKzXLPBNRBsRSvFBOCgu7KX1ewsNqQ8=',
      accountReferenceId: 'jg5mFCxvS9+soKzXLPBNRBsRSvFBOCgu7KX1ewsNqQ8=',
      accountNumberLastFour: '4800',
      accountNickname: '',
      productId: '4600'
    }
  ]
})
