import React, { createRef, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'
import lottie from 'lottie-web'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { log } from '@api/ccc-api-calls'
import { selectors as authSelectors } from '@modules/auth'
import {
  actions as transactionActionCreators,
  selectors as transactionSelectors
} from '@modules/transaction'
import { useIsContentOverflowing } from '@hooks/use-is-content-overflowing'
import { isOfflineModeOnAndAvailable } from '@util/check-offline-mode'
import animation from './anim-qr-demo.json'

const mapStateToProps = state => ({ ...state.transaction })
const mapDispatchToProps = dispatch => bindActionCreators(transactionActionCreators, dispatch)

const QrDemo = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const bodyElement = document.querySelector('body')
  const animContainer = createRef()
  const accessToken = useSelector(authSelectors.accessToken)
  const clientCorrelationId = useSelector(transactionSelectors.clientCorrelationId)
  const kioskName = useSelector(transactionSelectors.kioskName)
  const offlineMode = useSelector(transactionSelectors.offlineMode)
  const [isFundingDisclaimerConfirmed, setFundingDisclaimerConfirmed] = useState(false)
  const [isReversalDisclaimerConfirmed, setReversalDisclaimerConfirmed] = useState(false)
  const [isOverflowing, setIsOverflowing, contentContainerRef, btnRef] = useIsContentOverflowing(
    bodyElement,
    2,
    [[0, e => e.offsetTop]]
  )

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
        className: 'anim',
        id: 'qr-demo-gif'
      }
    })
    anim.addEventListener('DOMLoaded', () => setIsOverflowing(isOverflowing()))
    const qrGif = document.getElementById('qr-demo-gif')
    qrGif.setAttribute('aria-hidden', 'true')

    return () => anim.destroy()
  }, [animContainer, isOverflowing, setIsOverflowing])

  const formFields = {
    withdraw: {
      id: 'isFundingDisclaimerConfirmedCheckbox',
      text: t('qrDemo.fundingDisclaimer')
    },
    reverse: {
      id: 'isReversalDisclaimerConfirmedCheckbox',
      text: t('qrDemo.reversalDisclaimer')
    }
  }
  /* eslint-disable jsx-a11y/label-has-associated-control */
  const disclaimerConfirmationRow = (row, checkboxState, checkboxStateChange) => {
    return (
      <div
        className='confirm-disclaimer-checkbox'
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'flex-end',
          marginBottom: 20
        }}
      >
        <div
          className='grv-text__body--tiny qr-demo-disclaimer'
          style={{ marginLeft: 10, overflow: 'hidden' }}
        >
          {formFields[row].text}
        </div>
        <div>
          <input
            id={formFields[row].id}
            className='checkbox'
            type='checkbox'
            value='true'
            checked={checkboxState}
            onChange={() => checkboxStateChange(checkboxState => !checkboxState)}
          />
          <label
            aria-label={`${row} confirmation checkbox`}
            className='checkbox-label-left'
            htmlFor={formFields[row].id}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div ref={animContainer} style={{ height: '50%' }} role='presentation' />
      <div
        ref={contentContainerRef}
        style={{ textAlign: 'left', marginTop: '10px', marginBottom: '10px' }}
        className='content-container'
      >
        <h2 id='qr-demo-header' className='content-title'>
          {t('qrDemo.header')}
        </h2>

        <div className='subheader' style={{ marginBottom: '16px' }}>
          {t('qrDemo.subheader')}
        </div>

        {disclaimerConfirmationRow(
          'withdraw',
          isFundingDisclaimerConfirmed,
          setFundingDisclaimerConfirmed
        )}
        {disclaimerConfirmationRow(
          'reverse',
          isReversalDisclaimerConfirmed,
          setReversalDisclaimerConfirmed
        )}

        <button
          ref={btnRef}
          id='qr-demo-confirmation-button'
          type='button'
          className='action-button'
          style={{ marginTop: 12 }}
          disabled={!isFundingDisclaimerConfirmed || !isReversalDisclaimerConfirmed}
          onClick={() => {
            const logObject = JSON.stringify({
              'Checkbox Texts': [t('qrDemo.fundingDisclaimer'), t('qrDemo.reversalDisclaimer')]
            })
            if (!isOfflineModeOnAndAvailable(offlineMode)) {
              log(
                kioskName,
                clientCorrelationId,
                `Customer has checked checkboxes: ${logObject}`,
                accessToken
              )
            }
            navigate('/qr')
          }}
        >
          {t('qrDemo.buttonText')}
        </button>
      </div>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(QrDemo)
