import { call, put, select, takeLatest } from 'redux-saga/effects'
import { log } from '@api/ccc-api-calls'
import { defaultReducer } from '@util/default-reducer'
import { handleErrorDispatch } from '@util/error'
import { actionMatcher } from '@util/saga-action-matcher'
import { formatAmount, formatMemo, formatPayee } from '@util/check-validation'
import { convertDollarToCents, formatAmountToCurrency } from '@util/currency'
import { handleApiErrors } from '@util/api-error-detect'
import { isOfflineModeOnAndAvailable } from '@util/check-offline-mode'
import { selectors as transactionSelectors } from './transaction'
import { selectors as authSelectors } from './auth'
import { actions as errorActions } from './error'

export const initialState = {
  amount: '',
  memo: '',
  payee: '',
  errors: {
    payee: {},
    amount: {},
    memo: {}
  }
}

export const selectors = {
  payee: state => state.check.payee,
  memo: state => state.check.memo,
  displayAmount: state => `${formatAmountToCurrency(state.check.amount)}`
}

export const sagas = {
  *offerCheckDetailsThenGotoConfirmation({ payload: navigate, saga: { next, reject } }) {
    const accessToken = yield select(authSelectors.accessToken)
    const clientCorrelationId = yield select(transactionSelectors.clientCorrelationId)
    const kioskName = yield select(transactionSelectors.kioskName)
    const offlineMode = yield select(transactionSelectors.offlineMode)
    const memo = yield select(selectors.memo)
    const payee = yield select(selectors.payee)
    const amount = parseFloat(yield select(s => s.check.amount)).toFixed(2)

    const headers = {
      'kiosk-name': kioskName,
      'client-correlation-id': clientCorrelationId,
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json;v=3',
      'Content-Type': 'application/json;v=3'
    }

    if (isOfflineModeOnAndAvailable(offlineMode)) {
      yield put(next({}))
      yield call(navigate, '/check-confirmation')
      return
    }

    if (process.env.REACT_APP_NODE_ENV === 'DEV') {
      const profileReferenceId = yield select(authSelectors.profileReferenceId)
      headers.profilereferenceid = profileReferenceId
    }

    try {
      log(kioskName, clientCorrelationId, 'offering check details', accessToken)
      const response = yield fetch(`${process.env.REACT_APP_EXCHANGE_CCC_HOST_OL_ENDPOINT}/check`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: JSON.stringify({
          encryptedPayee: payee,
          payee,
          checkAmountCents: convertDollarToCents(amount),
          encryptedMemo: memo,
          memo
        })
      }).then(handleApiErrors)
      yield put(next(response))
      yield call(navigate, '/check-confirmation')
    } catch (e) {
      if (e.statusCode === 403) {
        log(kioskName, clientCorrelationId, e.toString(), accessToken)
        yield call(navigate, '/session-timeout')
      } else if (e.statusCode >= 400 && e.statusCode <= 499) {
        log(kioskName, clientCorrelationId, e.toString(), accessToken)
        yield put(errorActions.setGeneralError(e))
        if (e.name === 'InvalidState') {
          handleErrorDispatch(e.name, e.message)
        } else {
          yield put(reject(e))
        }
      } else {
        yield put(errorActions.setGeneralError(e))
        handleErrorDispatch(e.name, e.message)
      }
    }
  },
  *offerCheckConfirmationThenGotoQrDemo({ payload: navigate, saga: { next, reject } }) {
    const accessToken = yield select(authSelectors.accessToken)
    const clientCorrelationId = yield select(transactionSelectors.clientCorrelationId)
    const kioskName = yield select(transactionSelectors.kioskName)
    const offlineMode = yield select(transactionSelectors.offlineMode)

    if (isOfflineModeOnAndAvailable(offlineMode)) {
      yield put(next({}))
      yield call(navigate, '/qr-demo')
      return
    }

    try {
      log(
        kioskName,
        clientCorrelationId,
        `offering check confirmation with request body: ${JSON.stringify({ isConfirmed: true })}`,
        accessToken
      )

      const headers = {
        'kiosk-name': kioskName,
        'client-correlation-id': clientCorrelationId,
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json;v=3',
        'Content-Type': 'application/json;v=3'
      }

      if (process.env.REACT_APP_NODE_ENV === 'DEV') {
        const profileReferenceId = yield select(authSelectors.profileReferenceId)
        headers.profilereferenceid = profileReferenceId
      }

      const response = yield fetch(
        `${process.env.REACT_APP_EXCHANGE_CCC_HOST_OL_ENDPOINT}/confirm-check`,
        {
          method: 'POST',
          credentials: 'include',
          headers,
          body: JSON.stringify({
            isConfirmed: true
          })
        }
      ).then(handleApiErrors)
      yield put(next(response))
      yield call(navigate, '/qr-demo')
    } catch (e) {
      if (e.statusCode === 403) {
        log(kioskName, clientCorrelationId, e.toString(), accessToken)
        yield call(navigate, '/session-timeout')
      } else if (e.statusCode >= 400 && e.statusCode <= 499) {
        log(kioskName, clientCorrelationId, e.toString(), accessToken)
        yield put(errorActions.setGeneralError(e))
        if (e.name === 'InvalidState') {
          handleErrorDispatch(e.name, e.message)
        } else {
          yield put(reject(e))
        }
      } else {
        yield put(errorActions.setGeneralError(e))
        handleErrorDispatch(e.name, e.message)
      }
    }
  },
  *offerCheckConfirmationThenGotoEaseMobile({ payload: navigate, saga: { next, reject } }) {
    const accessToken = yield select(authSelectors.accessToken)
    const clientCorrelationId = yield select(transactionSelectors.clientCorrelationId)
    const kioskName = yield select(transactionSelectors.kioskName)
    try {
      log(
        kioskName,
        clientCorrelationId,
        `offering check confirmation with request body: ${JSON.stringify({ isConfirmed: true })}`,
        accessToken
      )
      const response = yield fetch(
        `${process.env.REACT_APP_EXCHANGE_CCC_HOST_OL_ENDPOINT}/confirm-check`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'kiosk-name': kioskName,
            'client-correlation-id': clientCorrelationId,
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json;v=3',
            'Content-Type': 'application/json;v=3'
          },
          body: JSON.stringify({
            isConfirmed: true
          })
        }
      ).then(handleApiErrors)
      yield put(next(response))
      yield call(navigate, '/ease-redirection')
    } catch (e) {
      if (e.statusCode === 403) {
        log(kioskName, clientCorrelationId, e.toString(), accessToken)
        yield call(navigate, '/session-timeout')
      } else if (e.statusCode >= 400 && e.statusCode <= 499) {
        log(kioskName, clientCorrelationId, e.toString(), accessToken)
        yield put(errorActions.setGeneralError(e))
        if (e.name === 'InvalidState') {
          handleErrorDispatch(e.name, e.message)
        } else {
          yield put(reject(e))
        }
      } else {
        yield put(errorActions.setGeneralError(e))
        handleErrorDispatch(e.name, e.message)
      }
    }
  }
}

export const actions = {
  setPayee: payee => ({
    type: actions.setPayee,
    payload: payee,
    reducer: (s, payee) => ({ ...s, payee: formatPayee(payee) })
  }),
  setAmount: amount => ({
    type: actions.setAmount,
    payload: amount,
    reducer: (s, amount) => ({ ...s, amount: formatAmount(amount) })
  }),
  setMemo: memo => ({
    type: actions.setMemo,
    payload: memo,
    reducer: (s, memo) => ({ ...s, memo: formatMemo(memo) })
  }),

  offerCheckDetailsThenGotoConfirmation: navigate => ({
    type: actions.offerCheckDetailsThenGotoConfirmation,
    saga: {
      init: sagas.offerCheckDetailsThenGotoConfirmation,
      next: actions.offerCheckDetailsOk,
      reject: actions.offerCheckDetailsKo
    },
    payload: navigate,
    reducer: s => s
  }),
  offerCheckDetailsOk: response => ({
    type: actions.offerCheckDetailsOk,
    payload: response,
    reducer: s => ({ ...s, errors: initialState.errors })
  }),
  offerCheckDetailsKo: e => ({
    type: actions.offerCheckDetailsKo,
    payload: { e },
    reducer: (s, { e }) => ({ ...s, errors: { ...s.errors, ...e } })
  }),

  offerCheckConfirmationThenGotoQrDemo: navigate => ({
    type: actions.offerCheckConfirmationThenGotoQrDemo,
    saga: {
      init: sagas.offerCheckConfirmationThenGotoQrDemo,
      next: actions.offerCheckConfirmationOk,
      reject: actions.offerCheckConfirmationKo
    },
    payload: navigate,
    reducer: s => s
  }),
  offerCheckConfirmationOk: response => ({
    type: actions.offerCheckDetailsOk,
    payload: response,
    reducer: s => ({ ...s, errors: initialState.errors })
  }),
  offerCheckConfirmationKo: e => ({
    type: actions.offerCheckDetailsKo,
    payload: e,
    reducer: (s, e) => ({ ...s, errors: { ...s.errors, ...e } })
  }),
  offerCheckConfirmationThenGotoEaseMobile: navigate => ({
    type: actions.offerCheckConfirmationThenGotoEaseMobile,
    saga: {
      init: sagas.offerCheckConfirmationThenGotoEaseMobile,
      next: actions.offerCheckConfirmationOk,
      reject: actions.offerCheckConfirmationKo
    },
    payload: navigate,
    reducer: s => s
  })
}

export const sagaWatchers = {
  *offerCheckDetailsThenGotoConfirmationWatcher() {
    yield takeLatest(
      actionMatcher(actions.offerCheckDetailsThenGotoConfirmation),
      actions.offerCheckDetailsThenGotoConfirmation().saga.init
    )
  },
  *offerCheckConfirmationThenGotoQrCodeDemonstrationWatcher() {
    yield takeLatest(
      actionMatcher(actions.offerCheckConfirmationThenGotoQrDemo),
      actions.offerCheckConfirmationThenGotoQrDemo().saga.init
    )
  },
  *offerCheckConfirmationThenGotoEaseMobileWatcher() {
    yield takeLatest(
      actionMatcher(actions.offerCheckConfirmationThenGotoEaseMobile),
      actions.offerCheckConfirmationThenGotoEaseMobile().saga.init
    )
  }
}

const defaultCheckReducer = defaultReducer(actions)
export const reducer = (s = initialState, a) => {
  const s2 = defaultCheckReducer(s, a)
  switch (a.type) {
    case errorActions.clearErrors:
      return { ...s2, errors: initialState.errors }
    default:
      return s2
  }
}
