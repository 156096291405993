import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { log, revokeAccessToken } from '@api/ccc-api-calls'
import {
  actions as transactionActions,
  selectors as transactionSelectors
} from '@modules/transaction'
import { actions as errorActions } from '@modules/error'
import { selectors as authSelectors } from '@modules/auth'
import { useActions } from '@hooks/use-actions'
import { GLOBAL_ERROR } from '@util/error'
import { mockConstants } from '@util/mock-constants'
import { isOfflineModeOnAndAvailable } from '@util/check-offline-mode'
import Loader from '@components/loader'
import { routes, wizards } from '../../routes'
import PageHeader from './page-header'
import ProtectedRoute from '../protected-route'

const App = () => {
  const accessToken = useSelector(authSelectors.accessToken)
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const salt = useSelector(transactionSelectors.salt)
  const kioskName = useSelector(transactionSelectors.kioskName)
  const offlineMode = useSelector(transactionSelectors.offlineMode)
  const location = useLocation()
  const navigate = useNavigate()
  const [locationArray, setLocationArray] = useState([])
  const [
    setGeneralError,
    clearErrors,
    fetchClientCorrelationId,
    fetchClientCorrelationIdOk,
    setCustomerCheckCentsLimit,
    setKioskName,
    setSalt,
    setOfflineMode
  ] = useActions([
    errorActions.setGeneralError,
    errorActions.clearErrors,
    transactionActions.fetchClientCorrelationId,
    transactionActions.fetchClientCorrelationIdOk,
    transactionActions.setCustomerCheckCentsLimit,
    transactionActions.setKioskName,
    transactionActions.setSalt,
    transactionActions.setOfflineMode
  ])

  const getUrlParam = param => {
    const params = new URLSearchParams(location.search)
    const value = params.get(param)
    return value
  }

  const requireKioskNameError = useMemo(() => {
    return {
      'kiosk-name':
        'The app requires a kiosk-name be set in the url search params (i.e. localhost:3000/?kiosk-name=Test+Kiosk+1)'
    }
  }, [])

  const requireSaltError = useMemo(() => {
    return {
      salt: 'The app requires a salt to be set in the url search params (i.e. localhost:3000/?salt=abcd1234)'
    }
  }, [])

  if (isOfflineModeOnAndAvailable(offlineMode)) {
    setCustomerCheckCentsLimit(mockConstants.customerCheckCentsLimit)
  }

  if (location.pathname === '/') {
    const kioskNameParam = getUrlParam('kiosk-name')
    const saltParam = getUrlParam('salt')
    const offlineModeParam = getUrlParam('offlineMode')

    setKioskName(kioskNameParam)
    setSalt(saltParam)
    setOfflineMode(offlineModeParam)
  }

  let clientCorrelationId = useSelector(transactionSelectors.clientCorrelationId)

  useEffect(() => {
    // logging current page of app when authenticated
    if (!isOfflineModeOnAndAvailable(offlineMode)) {
      log(kioskName, clientCorrelationId, `User is now on page ${location.pathname}`, accessToken)
    }
  }, [location, offlineMode])

  useEffect(() => {
    function redirectToError(e) {
      navigate('/error', {
        state: {
          errorTitle: e.detail.error.title,
          errorDetail: e.detail.error.detail
        },
        kioskName
      })
    }
    document.addEventListener(
      GLOBAL_ERROR,
      e => {
        redirectToError(e)
      },
      false
    )
    return () => {
      document.removeEventListener(GLOBAL_ERROR, e => {
        redirectToError(e)
      })
    }
  }, [navigate, kioskName, salt])

  useEffect(() => {
    if (!location.pathname.includes('/login') && !location.pathname.includes('/oauth2')) {
      setLocationArray(locationArray => [...locationArray, location])
    }
    clearErrors()
  }, [location, clearErrors])

  useEffect(() => {
    if (!salt) setGeneralError({ urlSearchParams: requireSaltError })
    else if (!kioskName) setGeneralError({ urlSearchParams: requireKioskNameError })
    else clearErrors()
  }, [salt, kioskName, clearErrors])

  useEffect(() => {
    if (isOfflineModeOnAndAvailable(offlineMode)) {
      clientCorrelationId = 'OFFLINE_MODE_CCID'
    }

    if (clientCorrelationId) {
      fetchClientCorrelationIdOk(clientCorrelationId)
    } else if (kioskName && !clientCorrelationId) {
      fetchClientCorrelationId()
    }
  }, [
    clientCorrelationId,
    fetchClientCorrelationId,
    fetchClientCorrelationIdOk,
    setGeneralError,
    requireKioskNameError,
    offlineMode,
    kioskName
  ])

  async function cancelTransaction() {
    try {
      const endpoint = `${process.env.REACT_APP_EXCHANGE_CCC_HOST_OL_ENDPOINT}/cancel-transaction`
      const res = await fetch(endpoint, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'client-correlation-id': clientCorrelationId,
          'kiosk-name': kioskName,
          Accept: 'application/json;v=3',
          'Content-Type': 'application/json;v=3',
          Authorization: `Bearer ${accessToken}`
        }
      })
      if (res.status !== 200) {
        if (res.status === 403) {
          navigate('/session-timeout')
        } else {
          throw new Error('Not 200 OK')
        }
      }

      await res.json()
    } catch (err) {
      if (isAuthenticated) {
        log(kioskName, clientCorrelationId, err.toString(), accessToken)
      } else {
        // eslint-disable-next-line no-console
        console.log(err.toString())
      }
    } finally {
      if (isAuthenticated) {
        revokeAccessToken(accessToken)
      }
    }
  }

  window.onpopstate = function () {
    const confirm = window.confirm(
      'Are you sure you want to leave the page? Your transaction will be lost.'
    )

    if (confirm) {
      cancelTransaction().then(() => {
        window.location.replace(process.env.REACT_APP_ACKNOWLEDGMENT_REDIRECT)
      })
    } else {
      navigate(locationArray[locationArray.length - 2])
    }
  }

  return (
    <>
      {
        // CCID always undefined on create transaction error resulting in
        // indefinite loader on error page.
        !location.pathname.includes('/error') && (!clientCorrelationId || !kioskName) ? (
          <Loader />
        ) : (
          <>
            <PageHeader />
            <Routes style={{ height: '100%' }}>
              <Route {...routes.login} />
              <Route {...routes.token} />
              {wizards.buyCashiersCheck.routes.map(route => {
                return route.protected ? (
                  <Route element={<ProtectedRoute />} key={route.path}>
                    <Route key={route.path} {...route} />
                  </Route>
                ) : (
                  <Route key={route.path} {...route} />
                )
              })}
              <Route {...routes.sessionTimeout} />
            </Routes>
          </>
        )
      }
    </>
  )
}

export default App
