import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { all, call, delay, spawn } from 'redux-saga/effects'
import { persistReducer, persistStore } from 'redux-persist'
import { getPersistConfig } from 'redux-deep-persist'
import storage from 'redux-persist/lib/storage'

import { reducer as checkReducer, sagaWatchers as checkSagaWatchers } from '@modules/check'
import {
  reducer as transactionReducer,
  sagaWatchers as transactionSagaWatchers
} from '@modules/transaction'
import {
  reducer as authReducer,
  sagaWatchers as authSagaWatchers,
  subscriptions as authSubscriptions
} from '@modules/auth'
import { reducer as errorReducer } from '@modules/error'
import { reducer as accountReducer, sagaWatchers as accountSagaWatchers } from '@modules/account'

const initialState = {}
const enhancers = []
const sagaMiddleware = createSagaMiddleware()
const appMiddleware = applyMiddleware(sagaMiddleware)
const appReducer = combineReducers({
  transaction: transactionReducer,
  check: checkReducer,
  account: accountReducer,
  auth: authReducer,
  error: errorReducer
})

export const REINIT = 'ccc-client-react/root/REINIT'
const rootReducer = (state, action) => {
  let newState = state
  if (action.type === REINIT) {
    newState = undefined
  }
  return appReducer(newState, action)
}

export const reinit = () => {
  return { type: REINIT }
}

if (process.env.NODE_ENV === 'development') {
  /* eslint-disable no-underscore-dangle */
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension({ trace: true }))
  }
}

/* eslint-disable no-console */
const makeRestartable = saga => {
  return spawn(function* () {
    let retries = 0
    while (retries < 10) {
      // infinite loop is not allowed by onepipeline
      try {
        yield call(saga)
        console.error(
          'unexpected root saga termination. The root sagas are supposed to be sagas that live during the whole app lifetime!',
          saga
        )
        retries = 0
      } catch (e) {
        console.error('Saga error, the saga will be restarted', e)
      }
      yield delay(1000) // Avoid infinite failures which would block the app
      retries++
    }
    console.error('Saga error, failed to restart saga before reaching maximum retry attempts')
  })
}

export function* rootSaga() {
  yield all(
    [
      ...Object.values(authSagaWatchers),
      ...Object.values(accountSagaWatchers),
      ...Object.values(transactionSagaWatchers),
      ...Object.values(checkSagaWatchers)
    ].map(makeRestartable)
  )
}

function createRootSubscriptions(store) {
  authSubscriptions.subscribeToOauthAuthorizationCode(store)
}

const persistConfig = getPersistConfig({
  key: 'root',
  storage,
  whitelist: [
    'transaction.clientCorrelationId',
    'transaction.offlineMode',
    'transaction.customerCheckCentsLimit',
    'transaction.kioskName',
    'transaction.salt'
  ],
  rootReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const configureStore = () => {
  const composedEnhancers = compose(appMiddleware, ...enhancers)
  const store = createStore(persistedReducer, initialState, composedEnhancers)
  const persistor = persistStore(store)
  createRootSubscriptions(store)
  sagaMiddleware.run(rootSaga)
  return { store, persistor }
}

export default configureStore
