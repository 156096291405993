import { call, put, select, takeLatest } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { log } from '@api/ccc-api-calls'
import { defaultReducer } from '@util/default-reducer'
import { actionMatcher } from '@util/saga-action-matcher'
import { getCents, getDollars } from '@util/currency'
import { handleErrorDispatch } from '@util/error'
import { handleApiErrors } from '@util/api-error-detect'
import { isOfflineModeOnAndAvailable } from '@util/check-offline-mode'
import { selectors as authSelectors } from './auth'
import { actions as errorActions } from './error'
import { selectors as transactionSelectors } from './transaction'

export const initialState = {
  accounts: [],
  account: {},
  customerPreviousCheckAmountSum: null,
  loading: false,
  errors: {
    accounts: {},
    account: {}
  }
}

/* eslint-disable no-use-before-define */
export const sagas = {
  /* TODO (ATMOF-713): Once we have DevEx up, ensure the get accounts endpoint and others require valid auth.
   */
  *fetchAccounts({ payload: navigate, saga: { next, reject } }) {
    const accessToken = yield select(authSelectors.accessToken)
    const clientCorrelationId = yield select(transactionSelectors.clientCorrelationId)
    const kioskName = yield select(transactionSelectors.kioskName)
    try {
      log(kioskName, clientCorrelationId, 'fetching accounts', accessToken)

      const headers = {
        'kiosk-name': kioskName,
        'client-correlation-id': clientCorrelationId,
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json;v=3',
        'Content-Type': 'application/json;v=3'
      }

      if (process.env.REACT_APP_NODE_ENV === 'DEV') {
        const profileReferenceId = yield select(authSelectors.profileReferenceId)
        headers.profilereferenceid = profileReferenceId
      }

      const response = yield fetch(
        `${process.env.REACT_APP_EXCHANGE_CCC_HOST_OL_ENDPOINT}/accounts`,
        {
          method: 'GET',
          credentials: 'include',
          headers
        }
      ).then(handleApiErrors)

      if (response.length === 0) {
        const e = new Error('No valid accounts')
        e.name = 'AccountError'
        e.message = 'Customer has no valid accounts'
        yield put(errorActions.setGeneralError(e))
        handleErrorDispatch(e.name, e.message)
      }
      yield put(errorActions.clearErrors())
      yield put(next(response))
    } catch (e) {
      if (e.statusCode === 403) {
        log(kioskName, clientCorrelationId, e.toString(), accessToken)
        if (e.name === 'AccountEntitlementError') {
          handleErrorDispatch('AccountError', e.message)
        } else {
          yield call(navigate, '/session-timeout')
        }
      } else if (e.statusCode >= 400 && e.statusCode <= 499) {
        const redirectErrors = [
          'InvalidState',
          'ExceededCheckCountCustomerLimit',
          'ExceededCheckValueCustomerLimit'
        ]
        log(kioskName, clientCorrelationId, e.toString(), accessToken)
        yield put(errorActions.setGeneralError(e))
        if (redirectErrors.includes(e.name)) {
          handleErrorDispatch(e.name, e.message)
        } else {
          yield put(reject(e))
        }
      } else {
        yield put(errorActions.setGeneralError(e))
        handleErrorDispatch(e.name, e.message)
      }
    }
  },
  *offerAccountThenGotoCheckForm({ payload: { account, navigate }, saga: { next, reject } }) {
    const kioskName = yield select(transactionSelectors.kioskName)
    const clientCorrelationId = yield select(transactionSelectors.clientCorrelationId)
    const accessToken = yield select(authSelectors.accessToken)
    const offlineMode = yield select(transactionSelectors.offlineMode)

    if (isOfflineModeOnAndAvailable(offlineMode)) {
      yield put(next(account))
      yield call(navigate, '/check')
      return
    }

    try {
      log(
        kioskName,
        clientCorrelationId,
        `offering account: ${JSON.stringify(account)}`,
        accessToken
      )

      const headers = {
        'kiosk-name': kioskName,
        'client-correlation-id': clientCorrelationId,
        'tokenized-account-reference-id': account.tokenizedAccountReferenceId,
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json;v=3',
        'Content-Type': 'application/json;v=3'
      }

      if (process.env.REACT_APP_NODE_ENV === 'DEV') {
        const profileReferenceId = yield select(authSelectors.profileReferenceId)
        headers.profilereferenceid = profileReferenceId
      }

      const response = yield fetch(
        `${process.env.REACT_APP_EXCHANGE_CCC_HOST_OL_ENDPOINT}/account`,
        {
          method: 'POST',
          credentials: 'include',
          headers,
          body: JSON.stringify({ accountLastFour: account.accountNumberLastFour })
        }
      ).then(handleApiErrors)
      yield put(next(account))
      yield put(actions.setCheckAmountSum(response.customerCheckAmountSum))
      yield call(navigate, '/check')
    } catch (e) {
      if (e.statusCode === 403) {
        log(kioskName, clientCorrelationId, e.toString(), accessToken)
        yield call(navigate, '/session-timeout')
      } else if (e.statusCode >= 400 && e.statusCode <= 499) {
        log(kioskName, clientCorrelationId, e.toString(), accessToken)
        yield put(errorActions.setGeneralError(e))
        if (e.name === 'InvalidState') {
          handleErrorDispatch(e.name, e.message)
        } else {
          yield put(reject(e))
        }
      } else {
        yield put(errorActions.setGeneralError(e))
        handleErrorDispatch(e.name, e.message)
      }
    }
  }
}

export const actions = {
  setCheckAmountSum: customerPreviousCheckAmountSum => ({
    type: actions.setCheckAmountSum,
    payload: customerPreviousCheckAmountSum,
    reducer: (s, customerPreviousCheckAmountSum) => {
      return { ...s, customerPreviousCheckAmountSum }
    }
  }),
  setAccounts: accounts => ({
    type: actions.setAccounts,
    payload: accounts,
    reducer: (s, accounts) => {
      return { ...s, accounts }
    }
  }),
  setAccount: account => ({
    type: actions.setAccount,
    payload: account,
    reducer: (s, account) => ({
      ...s,
      account
    })
  }),
  fetchAccounts: navigate => ({
    type: actions.fetchAccounts,
    saga: {
      init: sagas.fetchAccounts,
      next: actions.fetchAccountsOk,
      reject: actions.fetchAccountsKo
    },
    payload: navigate,
    reducer: s => ({ ...s, loading: true })
  }),
  fetchAccountsOk: accounts => ({
    type: actions.fetchAccountsOk,
    payload: accounts,
    reducer: (s, accounts) => ({
      ...s,
      accounts,
      loading: false,
      errors: initialState.errors
    })
  }),
  fetchAccountsKo: e => ({
    type: actions.fetchAccountsKo,
    payload: e,
    reducer: (s, e) => ({
      ...s,
      errors: { ...s.errors, ...e },
      loading: false
    })
  }),
  offerAccountThenGotoCheckForm: (account, navigate) => ({
    type: actions.offerAccountThenGotoCheckForm,
    saga: {
      init: sagas.offerAccountThenGotoCheckForm,
      next: actions.offerAccountOk,
      reject: actions.offerAccountKo
    },
    payload: { account, navigate },
    reducer: s => ({ ...s, loading: true })
  }),
  offerAccountOk: account => ({
    type: actions.offerAccountOk,
    payload: account,
    reducer: (s, account) => ({
      ...s,
      account,
      loading: false,
      errors: initialState.errors
    })
  }),
  offerAccountKo: e => ({
    type: actions.offerAccountKo,
    payload: e,
    reducer: (s, e) => ({
      ...s,
      errors: { ...s.errors, ...e },
      loading: false
    })
  })
}

export const getAccountName = (account, accountNumberLastFour) =>
  `${account.accountNickname || account.productName} ... ${accountNumberLastFour}`

export const selectors = {}
selectors.accountModule = state => state.account
selectors.accountLoading = state => state.account.loading
selectors.accounts = createSelector(selectors.accountModule, acctMod =>
  acctMod.accounts.map(a => ({
    ...a,
    accountNumberLastFour: a.accountNumberLastFour,
    accountName: getAccountName(a, a.accountNumberLastFour),
    availableDollars: getDollars(a.availableBalance),
    availableCents: getCents(a.availableBalance)
  }))
)
selectors.account = createSelector(selectors.accountModule, acctMod => ({
  ...acctMod.account,
  accountNumberLastFour: acctMod.account.accountNumberLastFour,
  accountName: getAccountName(acctMod.account, acctMod.account.accountNumberLastFour),
  availableDollars: getDollars(acctMod.account.availableBalance),
  availableCents: getCents(acctMod.account.availableBalance)
}))
selectors.customerPreviousCheckAmountSum = state => state.account.customerPreviousCheckAmountSum

export const sagaWatchers = {
  *fetchAccountsWatcher() {
    yield takeLatest(actionMatcher(actions.fetchAccounts), actions.fetchAccounts().saga.init)
  },
  *offerAccountThenGotoCheckFormWatcher() {
    yield takeLatest(
      actionMatcher(actions.offerAccountThenGotoCheckForm),
      actions.offerAccountThenGotoCheckForm().saga.init
    )
  }
}

const defaultAccountReducer = defaultReducer(actions)
export const reducer = (s = initialState, a) => {
  const s2 = defaultAccountReducer(s, a)
  switch (a.type) {
    case errorActions.clearErrors:
      return { ...s2, errors: initialState.errors }
    default:
      return s2
  }
}
