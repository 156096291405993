import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { reinit as storeReinit } from '@src/store'
import { getKioskAndTransactionStatus, log, revokeAccessToken } from '@api/ccc-api-calls'
import GrvIcon from '@components/grv-icon'
import PrintProgress from '@components/print-progress'
import { selectors as transactionSelectors } from '@modules/transaction'
import { useIsContentOverflowing } from '@hooks/use-is-content-overflowing'
import { useActions } from '@hooks/use-actions'
import { useInterval } from '@hooks/use-interval'
import { handleErrorDispatch } from '@util/error'
import { selectors as authSelectors } from '@modules/auth'
import { isOfflineModeOnAndAvailable } from '@util/check-offline-mode'

const PollPrintStatus = () => {
  const { t } = useTranslation()
  const reinit = useActions(storeReinit)
  const kioskName = useSelector(transactionSelectors.kioskName)
  const clientCorrelationId = useSelector(transactionSelectors.clientCorrelationId)
  const accessToken = useSelector(authSelectors.accessToken)
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const profileReferenceId = useSelector(authSelectors.profileReferenceId)
  const offlineMode = useSelector(transactionSelectors.offlineMode)
  const bodyElement = document.querySelector('body')
  const [contentContainerRef, btnRef] = useIsContentOverflowing(bodyElement, 2, [
    [0, (e, s) => e.offsetTop - parseInt(s.marginTop)]
  ])

  // Should be printing if already on this page.
  const [kioskStatus, setKioskStatus] = useState('PRINTING')
  const [checkStatus, setCheckStatus] = useState('')

  async function pollTransactionStatus() {
    if (isOfflineModeOnAndAvailable(offlineMode)) {
      setKioskStatus('PRESENTING')
    } else {
      try {
        const status = await getKioskAndTransactionStatus(
          kioskName,
          clientCorrelationId,
          accessToken,
          profileReferenceId
        )

        switch (status.transactionStatus) {
          case 'CANCELLED':
          case 'COMPLETE':
            setCheckStatus(status.transactionStatus)
            break
          case 'CHECK_NOT_TAKEN':
            if (status.moneyMovementStatus === 'REVERSAL_SUCCESS') {
              handleErrorDispatch('CheckNotTakenError', 'CheckNotTakenError')
            } else {
              handleErrorDispatch('ReversalError', 'ReversalError')
            }
            break
          case 'PRESENT_FAILURE':
            if (status.moneyMovementStatus === 'REVERSAL_SUCCESS') {
              handleErrorDispatch('PrinterOrPresenterError', 'FailedToPresentError')
            } else {
              handleErrorDispatch('ReversalError', 'ReversalError')
            }
            break
          case 'PRINTER_FAILURE':
            if (status.moneyMovementStatus === 'REVERSAL_SUCCESS') {
              handleErrorDispatch('PrinterOrPresenterError', 'FailedToPrintError')
            } else {
              handleErrorDispatch('ReversalError', 'ReversalError')
            }
            break
          case 'PAGE_TRANSITION_FAILURE':
            handleErrorDispatch(status.transactionStatus, null)
            break
          default:
            if (status.kioskStatus === 'OUT_OF_SERVICE') {
              handleErrorDispatch(status.kioskStatus, null)
            }
        }

        switch (status.kioskStatus) {
          case 'PRESENTING':
            setKioskStatus(status.kioskStatus)
            break
          default:
        }
      } catch (err) {
        if (isAuthenticated) {
          log(kioskName, clientCorrelationId, err.toString(), accessToken)
        }
        handleErrorDispatch(err.name, err.message)
      }
    }
  }

  useInterval(() => {
    // run poller that will redirect the user after we get a status that the qrCode has been scanned on the aeon
    pollTransactionStatus()
  }, 1000)

  if (kioskStatus === 'PRINTING') {
    return <PrintProgress />
  }
  if (kioskStatus === 'PRESENTING') {
    return (
      <>
        <div className='wizard-header-container'>
          <div className='action-bar-container' />
          <h1 id='take-check-header' className='wizard-page-title'>
            Take Your Cashier&apos;s Check
          </h1>
        </div>
        <div
          ref={contentContainerRef}
          style={{ textAlign: 'center' }}
          className='content-container'
        >
          <div style={{ margin: '40px' }}>
            <GrvIcon className='check-mark-icon' iconClass='ui-filled-checkmark-1-24' />
          </div>
          <h2 className='content-title'>{t('done.header')}</h2>
          <div style={{ margin: '0px 0px 60px' }}>{t('done.subheader')}</div>
          {checkStatus === 'COMPLETE' || isOfflineModeOnAndAvailable(offlineMode) ? (
            <button
              id='done-button'
              ref={btnRef}
              type='button'
              style={{ width: '100%', margin: 0 }}
              className='progress-button'
              onClick={async () => {
                if (isOfflineModeOnAndAvailable(offlineMode)) {
                  window.location.replace(process.env.REACT_APP_OFFLINE_ACKNOWLEDGMENT_REDIRECT)
                } else {
                  reinit()
                  await revokeAccessToken(accessToken)
                  window.location.replace(process.env.REACT_APP_ACKNOWLEDGMENT_REDIRECT)
                }
              }}
            >
              {t('done.gotIt')}
            </button>
          ) : null}
        </div>
      </>
    )
  }
  return null
}

export default PollPrintStatus
