import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import headerBackgroundJpg from '@assets/360_bg.jpg'
import { useActions } from '@hooks/use-actions'
import {
  actions as transactionActions,
  selectors as transactionSelector
} from '@modules/transaction'
import { clearSessionTimeout } from '@util/session-timer'
import { formatAmountToCurrency, formatCentsToDollarsAndCents } from '@util/currency'
import { isOfflineModeOnAndAvailable } from '@util/check-offline-mode'
import { useLocation } from 'react-router-dom'

const Landing = () => {
  const { t } = useTranslation()
  const clientCorrelationId = useSelector(transactionSelector.clientCorrelationId)
  const customerCheckCentsLimit = useSelector(transactionSelector.customerCheckCentsLimit)
  const kioskName = useSelector(transactionSelector.kioskName)
  const offlineMode = useSelector(transactionSelector.offlineMode)
  const headerBackgroundRef = useRef()
  const location = useLocation()
  const [fetchClientCorrelationIdOk, fetchClientCorrelationId, fetchCustomerCheckCentsLimit] =
    useActions([
      transactionActions.fetchClientCorrelationIdOk,
      transactionActions.fetchClientCorrelationId,
      transactionActions.fetchCustomerCheckCentsLimit
    ])

  const getUrlParam = param => {
    const params = new URLSearchParams(location.search)
    const value = params.get(param)
    return value
  }

  useEffect(() => {
    const continueExperience = getUrlParam('continueExperience')
    window.localStorage.setItem('continueExperience', continueExperience)
  }, [window])

  useEffect(() => {
    if (!isOfflineModeOnAndAvailable(offlineMode)) {
      fetchClientCorrelationId()
    }
  }, [fetchClientCorrelationId, offlineMode])

  clearSessionTimeout()

  const data = {
    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_OAUTH_AUTHORIZATION_CODE_HANDLER_ENDPOINT,
    grant_type: 'authorization_code',
    scope: 'openid',
    'response-type': 'code',
    state: JSON.stringify({
      'redirect-location-after-auth': '/account-selection',
      'kiosk-name': kioskName,
      'client-correlation-id': clientCorrelationId
    })
  }

  useEffect(() => {
    if (!isOfflineModeOnAndAvailable(offlineMode)) {
      fetchClientCorrelationIdOk(clientCorrelationId)
      fetchCustomerCheckCentsLimit()
    }
  }, [fetchClientCorrelationIdOk, fetchCustomerCheckCentsLimit, clientCorrelationId, offlineMode])

  useEffect(() => {
    headerBackgroundRef.current.style.backgroundImage = `url(${headerBackgroundJpg})`
  }, [headerBackgroundRef])

  return (
    <>
      <div className='header'>
        <div ref={headerBackgroundRef} className='header-background' />
        <h1 id='header-text-landing' className='header-text'>
          {t('landing.header')}
        </h1>
      </div>
      <div className='canvas'>
        <div className='content-box'>
          <h2 className='content-title'>{t('landing.subheader')}</h2>
          <span className='subheader'>{t('landing.fee')}</span>
          <span className='subheader'>
            {formatAmountToCurrency(formatCentsToDollarsAndCents(customerCheckCentsLimit))}
          </span>
          <span className='subheader'>{t('landing.limit')}</span>
          <ol className='landing-page-list'>
            <li>{t('landing.step1')}</li>
            <li>{t('landing.step2')}</li>
            {!isOfflineModeOnAndAvailable(offlineMode) && <li>{t('landing.step3')}</li>}
            <li>{t('landing.step4')}</li>
          </ol>

          {!isOfflineModeOnAndAvailable(offlineMode) && (
            <div id='mobile-app-notice' style={{ marginBottom: 30 }}>
              <span className='subheader'>{t('landing.appNotice1')}</span>
              <span className='subheader' style={{ textDecoration: 'underline' }}>
                {t('landing.appNotice2')}
              </span>
              <span className='subheader'>{t('landing.appNotice3')}</span>
            </div>
          )}

          <a
            id='landing-page-button'
            style={{ width: '100%', margin: '0px 0px 16px' }}
            className='action-button'
            href={
              !isOfflineModeOnAndAvailable(offlineMode)
                ? `${process.env.REACT_APP_AUTH_ENDPOINT}?${new URLSearchParams(data).toString()}`
                : `/login?${new URLSearchParams({
                    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
                    redirect_uri: '/oauth2/token',
                    grant_type: 'authorization_code',
                    scope: 'openid',
                    'response-type': 'code',
                    state: JSON.stringify({
                      'redirect-location-after-auth': '/account-selection',
                      'kiosk-name': kioskName,
                      'client-correlation-id': clientCorrelationId
                    })
                  }).toString()}`
            }
          >
            {t('landing.continueToSecureSignIn')}
          </a>
        </div>
      </div>
    </>
  )
}

export default Landing
